import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import ExtNavDialog from '../../../components/ExtNavDialog';

const LayerControl = ({ 
    layers, 
    layerVisibility, 
    handleLayerSelection,
    openOnLoad 
  }) => {
  const op = useRef(null);
  const buttonRef = useRef(null);

  const [currentLayer, setCurrentLayer] = useState(null);

  const onLayerChange = (e) => {
    const { name: layerId, checked } = e.target;
    handleLayerSelection(layerId, checked);
  };

  //Show the overlay panel on load, if openOnLoad is true
  // useEffect(() => {
  //   if (openOnLoad) {
  //     setTimeout(() => {
  //       op.current.show(null, buttonRef.current);
  //     }, 0);
  //   }
  // }, [openOnLoad]);

  return (
    <div className="layer-control">
      <Button
        ref={buttonRef}
        label='Data Layers'
        onClick={(e) => op.current.toggle(e)}
        icon="pi pi-database"
        aria-haspopup
        aria-controls="overlay_panel"
        tooltip="Select Data Sources"
        tooltipOptions={{ position: 'right' }}
        className="p-button-info p-button-raised"
      />
      <ExtNavDialog 
        visible={currentLayer !== null} 
        onHide={() => setCurrentLayer(null)} 
        onConfirm={() => window.open(currentLayer?.homeLink, '_blank')}
        siteInfo={currentLayer?.longLabel}
      />
      <OverlayPanel ref={op} id="overlay_panel" dismissable style={{ width: "25rem"}}>
        {layers.map((layer) => (
          <>
          <div key={layer.id} className="field-checkbox grid">
            <div className='flex align-items-center mb-2'>
              <Checkbox
                className='mr-2'
                inputId={layer.id}
                name={layer.id}
                checked={layerVisibility[layer.id]}
                onChange={onLayerChange}
              />
              <label htmlFor={layer.id} className='mr-2 font-bold'>
                {layer.label}
              </label>

              {layer.paint && layer.paint["circle-color"] && (
                <div
                  style={{
                    backgroundColor: Array.isArray(layer.paint["circle-color"])
                      ? layer.paint["circle-color"][3]
                      : layer.paint["circle-color"],
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    border: '1px solid #000',
                  }}
                />
              )}
            </div>
            <div className='col-12 pt-1'>
              <small>
                <Button 
                  label={layer.longLabel}
                  tooltip='Visit the Data Source' 
                  className='p-button-text p-button-sm p-button-info p-button-text p-button-rounded m-0 p-0 pt-1' 
                  onClick={() => setCurrentLayer(layer)}
                />
              </small>
            </div>
            <div className='col-12 pt-0'>
              <small>{layer.subTitle}</small>
            </div>
            <div>
            </div>
          </div>
          </>
        ))}
        <hr />
        <div className='text-sm align-items-center text-center'>
          More sources coming soon!
        </div>
      </OverlayPanel>
    </div>
  );
};

export default LayerControl;