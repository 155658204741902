import React, {useState} from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import ExtNavDialog from '../../ExtNavDialog';  

const GWSelectedDataTable = ({
  selectedFeatures,
  selectedLocations,
  setSelectedLocations,
  onSelectionChange,
  handleDownload,
  downloadLoading,
  toggleDataTable,
  setHydrographSelectedSite,
  setActiveTabIndex,
  plot_hygrogaphs,
  setMultiHydrographSites,
  setShowMultiHydrograph,
  setNmbgmrSelectedSite,
  setShowNmbgmrDialog,
  activeTabIndex
}) => {
    const [navDialogVisible, setNavDialogVisible] = useState(false); 
    const [navSiteInfo, setNavSiteInfo] = useState(null);
    const [navSiteLink, setNavSiteLink] = useState('');

  return (
    <>
    <ExtNavDialog 
        visible={navDialogVisible}
        onHide={() => setNavDialogVisible(false)}
        onConfirm={() => {
            window.open(navSiteLink, '_blank');
            setNavDialogVisible(false);
        }}
        siteInfo={navSiteInfo}
    />
    <Card
      title="Selected Features"
      subTitle={<span>
        Add sites to the table using the polygon selection tool.
      </span>}
    >
      <DataTable
        size="small"
        value={selectedFeatures}
        selection={selectedLocations}
        dataKey="id"
        onSelectionChange={onSelectionChange}
        stripedRows
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column sortable field={"properties.name"} header={"Name"}></Column>
        <Column
          header="Actions"
          body={(item) => (
            <div className="flex">
              {item.data_source_short === 'PVACD' && (
                <>
                  <Button
                    severity="warning"
                    style={{ marginRight: "0.2rem" }}
                    rounded
                    icon="pi pi-chart-line"
                    tooltip="View Hydrograph"
                    onClick={() => {
                      setHydrographSelectedSite([item]);
                      setActiveTabIndex(1);
                      toggleDataTable();
                      setSelectedLocations([item]);
                    }}
                  />
                  <Button
                    severity="success"
                    rounded
                    outlined
                    icon="pi pi-arrow-up-right"
                    tooltip="More Site information"
                    onClick={() => {
                      setHydrographSelectedSite([item]);
                      setActiveTabIndex(0);
                      toggleDataTable();
                      setSelectedLocations([item]);
                    }}
                  />
                </>
              )}
              {item.data_source_short === 'NMBGMR' && (
                <>
                <Button
                  severity="warning"
                  rounded
                  icon="pi pi-chart-line"
                  tooltip="View Hydrograph"
                  onClick={() => {
                    setNmbgmrSelectedSite(item);
                    setShowNmbgmrDialog(true);
                    setActiveTabIndex(1);
                    toggleDataTable();
                  }}
                />
                <Button
                  severity="success"
                  rounded
                  outlined
                  icon="pi pi-arrow-up-right"
                  tooltip="More Site information"
                  onClick={() => {
                    setNmbgmrSelectedSite(item);
                    setShowNmbgmrDialog(true);
                    setActiveTabIndex(0);
                    toggleDataTable();
                  }}
                />
                </>
              )}
              {item.data_source_short === 'USGS NWIS' && (
                <Button
                  severity="info"
                  rounded
                  outlined
                  icon="pi pi-window-maximize"
                  tooltip="Open USGS Site Page"
                  onClick={() => {
                    setNavSiteInfo(item.data_source);
                    setNavSiteLink(`https://waterdata.usgs.gov/monitoring-location/${item.properties.site_no}`, '_blank');
                    setNavDialogVisible(true);
                  }}
                />
              )}
            </div>
          )}
        />
        <Column sortable
         header={"Data Source"}
         body={(item) => (
              <Button 
                label={item.data_source_short}
                tooltip={`Visit the ${item.data_source_short} website`}
                className='p-button-text' 
                onClick={() => {
                    setNavSiteInfo(item.data_source);
                    setNavSiteLink(item.homeLink, '_blank');
                    setNavDialogVisible(true);
                }
                }
              />
            )}
         ></Column>
      </DataTable>
      {/* <Divider />
      {(!selectedLocations || selectedLocations.length === 0) && (
        <>
          <Button
            disabled={true}
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
          />
          <Button
            className="ml-2"
            disabled={true}
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
          />
        </>
      )}

      {selectedLocations && selectedLocations.length === 1 && (
        <>
          <Divider />
          <Button
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
            tooltip="Download CSV Data for Selected Location(s)"
            loading={downloadLoading}
            onClick={handleDownload}
          />
          <Button
            className="ml-2"
            disabled={true}
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
          />
        </>
      )}

      {selectedLocations && selectedLocations.length >= 2 && (
        <>
          <Divider />
          <Button
            rounded
            label="Download Data"
            icon="pi pi-download"
            severity="warning"
            tooltip="Download CSV Data for Selected Location(s)"
            loading={downloadLoading}
            onClick={handleDownload}
          />
          <Button
            className="ml-2"
            rounded
            label="Plot Multiple Sites"
            icon="pi pi-chart-line"
            severity="warning"
            tooltip="Plot Multi-site Hydrograph"
            onClick={() => {
              setMultiHydrographSites(selectedLocations);
              plot_hygrogaphs(selectedLocations, 'multi');
              setShowMultiHydrograph(true);
            }}
          />
        </>
      )} */}
    </Card>
    </>
  );
};

export default GWSelectedDataTable;