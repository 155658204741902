// ===============================================================================
// Copyright 2024 Jake Ross
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// ===============================================================================

import { settings } from "../../../settings";
import { nmbgmr_getJson } from "../../../util";
import { st2GetLocations } from "../../../st2client";
// import convert from "xml-js";
import xmljs from "xml-js";

const PVACD_IOTIDS = [
  9640, 9641, 9642, 9643, 9644, 9645, 9647, 9648, 9649, 9650,
  // 9646 // Bartlett old
];

export const ebid_gw_locations = {
  id: "weaver:gw:ebid",
  label: "EBID",
  paint: {
    "circle-radius": 4,
    "circle-color": "#3a44d5",
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
  url: `https://st2.newmexicowaterdata.org/FROST-Server/v1.1`,
  dataFetcher: async () => {
    let data = await st2GetLocations("EBID");
    const features = data.map((location) => {
      return {
        type: "Feature",
        geometry: location.location,
        properties: {
          ...location.properties,
          name: location.name,
          iotid: location["@iot.id"],
          selflink: location["@iot.selfLink"],
        },
      };
    });

    return { type: "FeatureCollection", features: features };
  },
};
export const bernco_gw_locations = {
  id: "weaver:gw:bernco",
  label: "BernCo",
  paint: {
    "circle-radius": 4,
    "circle-color": "#44d53a",
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
  url: `https://st2.newmexicowaterdata.org/FROST-Server/v1.1`,
  dataFetcher: async () => {
    let data = await st2GetLocations("BernCo");
    console.log("basdfce", data);
    const features = data.map((location) => {
      // let linked_location = await fetch(
      //   `${location.properties.links[0]["@iot.selfLink"]}?$expand=Things`,
      // ).then((res) => res.json());

      // location.location.coordinates.push(
      //   linked_location.properties["Altitude"],
      // );
      return {
        type: "Feature",
        geometry: location.location,
        properties: {
          ...location.properties,
          // well_depth: {
          //   value: linked_location["Things"][0].properties["WellDepth"],
          // },
          iotid: location["@iot.id"],
          selflink: location["@iot.selfLink"],
          name: location.name,
        },
      };
    });

    return { type: "FeatureCollection", features: features };
  },
};

export const pvacd_gw_locations = {
  id: "weaver:gw:pvacd",
  label: "PVACD",
  longLabel: "Pecos Valley Artesian Conservancy District",
  subTitle: "",
  homeLink: "https://pvacd.com/",
  paint: {
    "circle-radius": 4,
    "circle-color": "#d5633a",
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
  url: `https://st2.newmexicowaterdata.org/FROST-Server/v1.1`,
  dataFetcher: async () => {
    let data = await st2GetLocations("PVACD");
    data = data.filter((f) => PVACD_IOTIDS.includes(f["@iot.id"]));

    const features = data.map(async (location) => {
      // let linked_location = await fetch(
      //   `${location.properties.links[0]["@iot.selfLink"]}?$expand=Things`,
      // ).then((res) => res.json());

      // location.location.coordinates.push(
      //   linked_location.properties["Altitude"],
      // );

      let well_depth = { value: 0, unit: "ft" };
      let hole_depth = { value: 0, unit: "ft" };
      if (location["Things"][0].properties["well_depth"]) {
        well_depth = location["Things"][0].properties["well_depth"];
      }
      if (location["Things"][0].properties["WellDepth"]) {
        hole_depth = location["Things"][0].properties["hole_depth"];
      }

      return {
        type: "Feature",
        geometry: location.location,
        properties: {
          ...location.properties,
          well_depth: well_depth,
          hole_depth: hole_depth,
          name: location.name,
          iotid: location["@iot.id"],
          selflink: location["@iot.selfLink"],
        },
      };
    });

    return { type: "FeatureCollection", features: await Promise.all(features) };
  },
};

export const usgs_nwis_gw_locations = {
  id: "weaver:gw:usgs",
  label: "USGS NWIS",
  longLabel: "USGS National Water Information System",
  subTitle: "Groundwater - Active Sites",
  homeLink: "https://waterdata.usgs.gov/nwis/gw",
  paint: {
    "circle-radius": 4,
    "circle-color": "#4d608f",
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
  // url: `${settings.apim_url}nwis_nm_gw_locations?stateCd=NM&siteType=GW&siteStatus=active`,
  url: `${settings.nwis_url}site?stateCd=NM&siteType=GW&siteStatus=active`,
  dataFetcher: async () => {
    let features = await fetch(
      // `${settings.apim_url}nwis_nm_gw_locations?stateCd=NM&siteType=GW&siteStatus=active`,
      `${settings.nwis_url}site?stateCd=NM&siteType=GW&siteStatus=active&format=mapper`,
    )
      .then((res) => res.text())
      .then((xmltext) => {
        return JSON.parse(xmljs.xml2json(xmltext, { compact: true }));
      })
      .then((data) => {
        return data.mapper.sites.site.map((site) => {
          const attrs = site._attributes;
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [attrs.lng, attrs.lat, 0],
            },
            properties: {
              name: attrs.sna,
              site_no: attrs.sno,
            },
          };
        });
      });
    return { type: "FeatureCollection", features: features };
    // .then((usgs_locations) => {
    // return make_usgs_feature_collection(usgs_locations);
    // });
  },
};

export const nmbgmr_gw_locations = {
  id: "weaver:gw:nmbgmr",
  label: "NMBGMR",
  longLabel: "New Mexico Bureau of Geology and Mineral Resources",
  subTitle: "Aquifer Mapping Program (AMP)",
  homeLink: "https://geoinfo.nmt.edu/resources/water/amp/home.html",
  dataFetcher: async () => {
    let data = await nmbgmr_getJson("locations?expand=false");
    data.features = data.features.filter(
      (f) => f.properties.public_release === true,
    );
    return data;
  },
  url: `${settings.nmbgmr_api_url}locations?expand=false`,
  paint: {
    "circle-radius": 4,
    "circle-color": "#517938",
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
};

export const trends = {
  id: "weaver:gw:nmbgmr:trends",
  label: "NMBGMR Trends",
  url: `${settings.nmbgmr_api_url}waterlevels/trends`,
  dataFetcher: async () => {
    return await nmbgmr_getJson("waterlevels/trends");
  },
  paint: {
    "circle-radius": 4,
    // "circle-color": "#d5633a",
    "circle-color": [
      "match",
      ["get", "trend", ["get", "manual_trend"]],
      "Stable",
      "#d7e1fc",
      "Decreasing",
      "#0042f7",
      "Increasing",
      "#fa6902",
      "#000000",
    ],
    "circle-stroke-width": 1,
    "circle-stroke-color": "#000000",
  },
};
// ============= EOF =============================================
